export const menuItems = [
  // {
  //   name: "docs",
  //   label: "Docs",
  // },
  // {
  //   name: "#pricing",
  //   label: "Pricing",
  // },
  {
    name: "signin",
    label: "Login",
  },
  // {
  //   name: "pages",
  //   label: "pages",
  //   items: [
  //     { name: "about", label: "About Us" },
  //     {
  //       name: "careers",
  //       label: "Careers",
  //       items: [
  //         { name: "career", label: "Career" },
  //         { name: "career-details", label: "Career Details" },
  //       ],
  //     },
  //     {
  //       name: "utility",
  //       label: "Utility",
  //       items: [
  //         { name: "pricing", label: "Pricing & Plan" },
  //         { name: "contact", label: "Contact" },
  //         { name: "faq", label: "FAQ" },
  //         { name: "comingsoon", label: "Coming soon" },
  //         { name: "terms", label: "Terms & Conditions" },
  //         { name: "404", label: "404 Page" },
  //       ],
  //     },
  //     {
  //       name: "account",
  //       label: "Account",
  //       items: [
  //         { name: "signin", label: "Sign In" },
  //         { name: "signin-image", label: "Sign In Image" },
  //         { name: "signup", label: "Sign Up" },
  //         { name: "signup-image", label: "Sign Up Image" },
  //         { name: "reset-pass", label: "Reset Password" },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "https://uxtheme.net/product-support/",
  //   label: "Support",
  //   isExternal: true,
  // },
];
