import React from "react";
import ReactDOM from 'react-dom'
import LogRocket from 'logrocket';
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";
import { ENV } from "./src/config";

if (ENV === "prod") {
  LogRocket.init('hnczam/staging-web');
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
